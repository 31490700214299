//ant.design
.ant-collapse-content {
  overflow: visible;
}

.ant-descriptions-item-label {
  font-weight: bold;
}

.ant-table {
  border: 1px solid #eee;

  .ant-table-body {

    overflow: auto !important;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(193, 193, 193, 1);
      -webkit-box-shadow: inset 0 0 6px rgba(193, 193, 193, 1);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $blue_color;
      border-radius: 10px;
    }
  }

}


//.ant-table-layout-fixed table {
//  table-layout: auto;
//}

.ant-btn-primary {
  color: #fff;
  background-color: $blue_color;
  border-color: $blue_color;
}

.ant-avatar-lg {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.ant-layout-sider-children, .ant-layout-sider {
  background-color: #fff;
}


//.ant-card-head{
//  background-color: #2E3193;
//  color: #fff;
//}

//.ant-card-bordered {
//  border-radius:1rem;
//  img{
//    border-radius:1rem 1rem 0 0;
//  }
//}
//.ant-card-actions {
//  border-radius: 0 0 1rem 1rem;
//}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  width: 94%;
  background-color: #2E3193;
  color: #fff;
  border-radius: 0 50px 50px 0;
}

.ant-modal-body {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(193, 193, 193, 1);
    -webkit-box-shadow: inset 0 0 6px rgba(193, 193, 193, 1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue_color;
    border-radius: 10px;
  }
}

