@import './common.scss';
@import "./result.css";
@import "custom_antd";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  .os-modal {
    padding: 20px;

  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* ============  Start Custom CSS =========== */

ul:empty {
  display: none;
}

/* APP layout */
.app_wrapper {

  @include fullDisplay;

  .app_page {
    //margin: 5px 16px;
    margin: 5px 16px 0 32px;
    height: calc(100vh - 60px);

    .page_header {
      margin-bottom: 2px;
    }

    .page_content {
      height: calc(100vh - 136px);
      width: 100%;
      overflow: auto;
      background: #fff;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(193, 193, 193, 1);
        -webkit-box-shadow: inset 0 0 6px rgba(193, 193, 193, 1);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        //background-color: #3790FF;
        background-color: $blue_color;
        border-radius: 10px;
      }

      .page_content_child {
        overflow: hidden;
        padding: 16px 24px;
      }

    }

  }

}

.custom-switch {
  background-color: red;

  &.ant-switch-checked {
    background-color: #2E3193;
  }
}

.input-text-align-right {
  input {
    text-align: right;
    padding-right: 25px;
  }
}

.disabled {
  color: rgba(255, 0, 0, .3);
}

.dead {
  color: rgba(222, 10, 10, 0.48);
}

.table-disabled-style {
  color: rgba(255, 0, 0, .3);
}

.table-selected-color {
  background-color: #40af0947;
}

// Focus color

.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #4c51a1;
}

.ant-drawer-close:focus {
  text-decoration: none;
  color: #4c51a1;
  background-color: #4c51a173;
}

.ant-modal-close:focus {
  color: #4c51a1;
  background-color: #4c51a173;
}

//.ant-btn-primary:focus, .ant-btn-danger:focus {
//  color: #fff;
//  background-color: #4c51a173;
//  border-color: #4c51a1;
//}

.ant-btn:focus, .ant-btn-lg:focus {
  color: #fff !important;
  background-color: #4c51a173 !important;
  border-color: #4c51a1 !important;
}

// select field background
.ant-select-selection {
  background-color: #c7e7ef;
}

.ant-input-number-input-wrap {
  background-color: #c7e7ef;
}

.ant-input {
  background-color: #c7e7ef;
}


// Table action button design

@mixin parent-icon-wrapper {
  display: inline-grid;
  border: 1px solid #dc3545;
  padding: 5px;
  cursor: pointer;
}

.action-wrapper {
  display: flex;
  justify-content: center;

  .icon-wrapper {
    display: inline-grid;
    margin-right: 5px;
    border: 1px solid #4C51A1;
    padding: 5px;
    cursor: pointer;

    .enter-icon {
      border: 1px solid #4C51A1;
      margin-bottom: 7px;
      padding: 0 3px;
      color: #4C51A1;
    }

    .edit-icon {
      color: #4C51A1;
      font-size: 18px;
    }
  }

  .delete-icon-wrapper {
    @include parent-icon-wrapper;
    padding: 3px;
    margin-right: 5px;

    .esc-icon {
      border: 1px solid #dc3545;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 3px;
      color: #dc3545;
    }

    .delete-icon {
      color: #dc3545;
      font-size: 18px;
    }
  }

  .ins-icon-wrapper {
    @include parent-icon-wrapper;
    margin-right: 5px;
    border: 1px solid #28a745;

    .ins-icon {
      border: 1px solid #28a745;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 3px;
      color: #28a745;
    }

    .view-icon {
      color: #28a745;
      font-size: 18px;
    }
  }

  .print-icon-wrapper {
    @include parent-icon-wrapper;
    border: 1px solid gray;
    margin-right: 5px;

    .p-icon {
      border: 1px solid gray;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 6px;
      color: gray;
    }

    .printer-icon {
      color: gray;
      font-size: 18px;
    }
  }

  .checked-icon-wrapper {
    @include parent-icon-wrapper;
    border: 1px solid #4C51A1;

    .esc-icon {
      border: 1px solid #4C51A1;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 3px;
      color: #4C51A1;
    }

    .printer-icon {
      color: #4C51A1;
      font-size: 18px;
    }
  }
}

//Table hover color disabled
.ant-table-tbody {
  > tr.ant-table-row {
    &:hover {
      > td {
        background: none !important;
      }
    }
  }
}

// checkbox style
.ant-checkbox {
  background-color: #2E3193;
  padding: 4px;
}

.table-selected-negative-color {
  background-color: #dc354547;
}

.table-selected-grey-color {
  background-color: #cccaca;
}

.sale-price-blinking {
  display: inline-block;
  border: 1px solid #C82333;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;

  .ant-input-number-input-wrap {
    background-color: #ffc6c6;
    color: black;

  }

  .ant-input {
    background-color: #ffc6c6;
    color: black;
  }

}

@keyframes blinking {
  50% {
    border-color: #fff;
  }
}

.vendor .ant-select-selection {
  margin-top: 4px;
  overflow: hidden;
}

.hide-handler-wrap {
  .ant-input-number-handler-wrap {
    display: none;
  }
}